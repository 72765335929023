<template>
  <v-row>
    <v-dialog
      :fullscreen="xsOnly"
      transition="scale-transition"
      persistent
      scrollable
      v-model="modelDialog"
      max-width="900px"
    >
      <v-card outlined>
        <v-card-title class="subtitle-1 font-weight-bold">
          {{ isEdited ? $t("class_list.edit") : $t("class_list.add") }}
          {{ $t("class_list.master_class") }}
        </v-card-title>
        <v-divider class="mb-2" />
        <v-card-text>
          <v-form ref="addMasterClass" lazy-validation>
            <v-card
              class="mt-5"
              v-for="(d, i) in masterClass"
              :key="i"
              outlined
            >
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-select
                        v-model="masterClass[i].id_grade"
                        :items="dataGrade"
                        :label="$t('class_list.select_grade')"
                        :rules="formValidation($t('app.required'))"
                        outlined
                        item-value="id"
                        item-text="grade"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="masterClass[i].name"
                        :rules="formValidation($t('app.required'))"
                        :label="$t('class_list.class_name')"
                        dense
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select
                        v-model="masterClass[i].school_year"
                        :items="dataSchoolYears"
                        :label="$t('class_list.school_year')"
                        :item-text="
                          item => `${item.start_year} / ${item.end_year}`
                        "
                        :readonly="isEdited"
                        item-value="id"
                        outlined
                        dense
                      >
                        <template #item="{ item }">
                          <span class="caption">
                            {{ item.start_year }} / {{ item.end_year }}
                          </span>
                          <v-tooltip right>
                            <template #activator="{ on }">
                              <v-icon
                                v-on="on"
                                :color="schoolYearInfo(item.status).color"
                                small
                                class="ml-1"
                                >{{ schoolYearInfo(item.status).name }}</v-icon
                              >
                            </template>
                            <span>{{ schoolYearInfo(item.status).info }}</span>
                          </v-tooltip>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-autocomplete
                        v-model="masterClass[i].homeroom_teacher"
                        :items="dataTeacher"
                        :label="$t('class_list.choose_guardian')"
                        :rules="[
                          value => value.length !== 0 || $t('app.required')
                        ]"
                        :loading="loadingTeacher"
                        item-value="id"
                        item-text="name"
                        outlined
                        dense
                        multiple
                      >
                        <template v-slot:selection="{ item, index }">
                          <span v-if="index === 0" class="mr-2">{{
                            item.name
                          }}</span>
                          <span v-if="index === 1" class="grey--text">
                            (+{{ masterClass[i].homeroom_teacher.length - 1 }}
                            {{ $t("class_list.others") }})
                          </span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-autocomplete
                        v-model="masterClass[i].conselor_teacher"
                        :items="dataTeacher"
                        :label="$t('class_list.table.name_conselor')"
                        item-value="id"
                        item-text="name"
                        outlined
                        dense
                        multiple
                      >
                        <template v-slot:selection="{ item, index }">
                          <span v-if="index === 0" class="mr-2">
                            {{ item.name }}
                          </span>
                          <span v-if="index === 1" class="grey--text">
                            (+{{ masterClass[i].conselor_teacher.length - 1 }}
                            {{ $t("class_list.others") }})
                          </span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4" :class="!isEdited && 'd-flex'">
                      <v-autocomplete
                        v-model="masterClass[i].major"
                        :items="majors"
                        :label="$t('master_data.student.select_major')"
                        :loading="loadingMajorList"
                        :rules="
                          isMandatoryMajor
                            ? formValidation($t('app.required'))
                            : []
                        "
                        outlined
                        dense
                        item-text="name"
                        return-object
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-spacer />
                      <v-btn
                        v-if="
                          ((i == 0 && masterClass.length == 1) ||
                            (masterClass > 1 && i == masterClass.length - 1) ||
                            i == masterClass.length - 1) &&
                            !isEdited
                        "
                        icon
                        dark
                        class="primary mr-3"
                        @click="addCard"
                      >
                        <v-icon size="30"> mdi-plus-circle-outline</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="i != 0 && !isEdited"
                        icon
                        dark
                        class="error"
                        @click="removeCard(i)"
                      >
                        <v-icon size="30">mdi-delete-forever</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <div class="mt-5">
            <v-btn outlined color="primary" class="mr-3" @click="closeDialog">
              {{ $t("app.back") }}
            </v-btn>
            <v-btn
              :loading="loadingSave"
              outlined
              class="gradient-primary"
              dark
              :disabled="
                isEdited
                  ? masterClass[0].school_year != null
                    ? false
                    : true
                  : false
              "
              @click="saveMasterClass"
            >
              {{ $t("app.save") }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { isFormValid } from "@/utils/validate";
import {
  get_teacher,
  post_master_class,
  update_class
} from "@/api/admin/academic/classList";
import { majorList } from "@/api/admin/master";
import i18n from "@/i18n";
import { mapGetters } from "vuex";
import { createMajorList, mandatoryMajor } from "@/utils/major";

export default {
  props: {
    selectedSchoolYear: Number,
    dataEdit: Object,
    isEdited: {
      type: Boolean,
      required: true
    },
    modelDialog: {
      type: Boolean,
      required: true
    },
    dataSchoolYears: {
      type: Array,
      required: true
    },
    dataGrade: {
      type: Array,
      required: true
    }
  },
  watch: {
    dataEdit(value) {
      if (this.isEdited) {
        this.masterClass[0].school_year = value.school_year_master;
        this.masterClass[0].grade = value.grade;
        this.masterClass[0].id_grade = value.id_grade;
        this.masterClass[0].name = value.name;
        this.masterClass[0].major = value.major;
        this.masterClass[0].homeroom_teacher = value.homeroom_teachers.map(
          d => d.employee
        );
        this.masterClass[0].conselor_teacher = value.conselor_teachers.map(
          d => d.employee
        );
        this.$nextTick(() => {
          this.$refs.addMasterClass.resetValidation();
        });
      }
    },
    modelDialog() {
      this.getTeacher();
      this.getMajors();
      if (!this.isEdited) {
        this.$nextTick(() => {
          this.getData();
          this.$refs.addMasterClass.resetValidation();
        });
      }
    }
  },
  computed: {
    ...mapGetters(["g_level_school"]),
    isMandatoryMajor() {
      return mandatoryMajor();
    }
  },
  data() {
    return {
      xsOnly: this.$vuetify.breakpoint.xsOnly,
      loadingTeacher: false,
      loadingSave: false,
      dataTeacher: [],
      masterClass: [
        {
          grade: null,
          id_grade: null,
          name: null,
          school_year: null,
          homeroom_teacher: [],
          conselor_teacher: null,
          major: null
        }
      ],
      majors: [],
      loadingMajorList: false
    };
  },
  methods: {
    getMajors() {
      this.loadingMajorList = true;
      this.majors = [];
      majorList()
        .then(res => {
          if (res.data.code) {
            const data = res.data.data;
            this.majors = createMajorList(data);
          } else {
            console.error(res.data.message);
          }
        })
        .catch(e => console.error(e))
        .finally(() => (this.loadingMajorList = false));
    },
    schoolYearInfo(status) {
      let icon = { name: "", color: "", info: "" };
      const active = {
        name: "mdi-check-circle-outline",
        color: "success",
        info: this.$i18n.t("school_year.current_school_year")
      };
      const willCome = {
        name: "mdi-timer-sand",
        color: "grey",
        info: this.$i18n.t("school_year.upcoming_school_year")
      };
      switch (status) {
        case "ACTIVE":
          icon = active;
          break;
        case "WILLCOME":
          icon = willCome;
          break;
      }
      return icon;
    },
    addCard() {
      this.masterClass.push({
        grade: null,
        name: null,
        school_year: this.selectedSchoolYear,
        homeroom_teacher: [],
        conselor_teacher: null
      });
    },
    removeCard(index) {
      this.masterClass.splice(index, 1);
    },
    closeDialog() {
      this.$refs.addMasterClass.reset();
      this.masterClass = [];
      this.$emit("toggleDialog");
    },
    getData() {
      this.masterClass = [
        {
          grade: null,
          name: null,
          school_year: this.selectedSchoolYear,
          homeroom_teacher: [],
          conselor_teacher: null,
          major: null
        }
      ];

      this.$emit("getClassList");
    },
    getTeacher() {
      this.loadingTeacher = true;
      get_teacher()
        .then(res => {
          this.dataTeacher = res.data.data;
          this.loadingTeacher = false;
        })
        .catch(() => {
          this.loadingTeacher = false;
        });
    },
    formValidation(text, type) {
      return isFormValid(text, type);
    },
    saveMasterClass() {
      this.loadingSave = true;
      this.masterClass[0].id = this.dataEdit.id;
      const body = this.masterClass[0];
      if (this.$refs.addMasterClass.validate()) {
        if (this.isEdited) {
          update_class(body)
            .then(res => {
              if (res.data.code) {
                this.snackBar(
                  false,
                  i18n.t("class_list.response.success_edit")
                );
                this.closeDialog();
              } else {
                this.snackBar(true, res.data.message);
              }
            })
            .catch(e => {
              this.snackBar(true, e);
            });
        } else {
          console.log(this.masterClass);
          post_master_class({ class: this.masterClass })
            .then(res => {
              if (res.data.code) {
                this.snackBar(false, i18n.t("class_list.response.success"));
                this.closeDialog();
              } else {
                this.snackBar(true, res.data.message);
              }
            })
            .catch(e => {
              this.snackBar(true, e);
            });
        }
      } else {
        this.loadingSave = false;
      }
    },
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
      this.loadingSave = false;
    }
  }
};
</script>
